import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { XytSharedModule, XytUserEditModule } from 'ng-bigxyt';
import { HeaderComponent } from './pages/header/header.component';
import { SidebarComponent } from './pages/sidebar/sidebar.component';
import { WrapperComponent } from './pages/wrapper/wrapper.component';
import { ModulesLoadedService } from './services/modules-loaded.service';

@NgModule({
  declarations: [SidebarComponent, HeaderComponent, WrapperComponent],
  imports: [NgbDropdownModule, XytSharedModule, XytUserEditModule, FontAwesomeModule],
  exports: [SidebarComponent, HeaderComponent, WrapperComponent, FontAwesomeModule],
  providers: [ModulesLoadedService]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
