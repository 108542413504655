import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GetHtmlService, HtmlType } from '../../services/get-html.service';

@Component({
  templateUrl: './signup.component.html'
})
export class SignupComponent {
  msg: string;
  appName: string;
  template: any;

  constructor(
    private service: GetHtmlService,
    private sanitizer: DomSanitizer
  ) {
    this.appName = 'Metrics Tracker';
    this.service.getHtml(HtmlType.agreement).subscribe({
      next: this.createTemplate.bind(this)
    });
  }

  private createTemplate(data: any) {
    this.template = this.sanitizer.bypassSecurityTrustHtml(data);
  }

  showTermsAndConditions() {
    this.msg = `${this.appName} Trial Terms and Conditions<br/>
    12<sup>th</sup> of February 2021`;
  }

  closeWindow() {
    this.msg = '';
  }
}
