import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as regularIcons from '@fortawesome/free-regular-svg-icons';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import { HttpOptionService } from 'ng-bigxyt';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LcMenuEntry } from 'src/app/menu.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() organizationConfigMenu: LcMenuEntry[];
  style: string;
  hasEnterprise: boolean;
  deactiveSidebar = true;
  lastOpenedSidebar: string;
  openedSidebar: string;
  showSidebar = 'Hide menu';
  wide = true;
  smallWindowLimit = 850;
  smallWindow = false;
  closedManualy: boolean;
  fakeResize: boolean;
  activeUrl = '';
  routerSubscr: Subscription;
  paths = [];
  pathsObj = {
    executionAnalytics: {
      url: 'realtime',
      title: 'REALTIME',
      show: true,
      icons: {
        solid: { faIcon: solidIcons.faChartLine },
        regular: { faIcon: solidIcons.faChartLine }
      }
    }
  };

  rightIcon = solidIcons.faChevronRight;
  downIcon = solidIcons.faChevronDown;
  outdentIcon = solidIcons.faOutdent;
  indentIcon = solidIcons.faIndent;
  blogIcon = solidIcons.faBlog;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    if (!this.fakeResize) {
      if (window.innerWidth < this.smallWindowLimit) {
        this.smallWindow = true;
        this.wide = false;
        this.openedSidebar = '';
      } else {
        if (!this.closedManualy) {
          this.wide = true;
          this.openedSidebar = this.lastOpenedSidebar;
          this.showSidebar = 'Hide menu';
        }
        this.smallWindow = false;
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  getPosition(event?: any) {
    const offset = window.pageXOffset;
    let pos = 225 - offset;
    if (!this.wide) {
      pos = 5 - offset;
    }
    return `${pos}px`;
  }

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private optionService: HttpOptionService
  ) {
    this.style =
      this.optionService.config.iconStyle && this.optionService.config.iconStyle === 'regular'
        ? this.optionService.config.iconStyle
        : 'solid';
  }

  ngOnInit() {
    // for login
    if (this.router.routerState.snapshot.url) {
      this.handleActiveUrl(this.router.routerState.snapshot.url);
    }
    // for logout and navigation
    this.routerSubscr = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects),
        filter((url: string) => url !== '')
      )
      .subscribe({
        next: this.handleActiveUrl.bind(this)
      });
    // after click at header menu router link sidebar should not catch mouseenter events
    setTimeout(() => {
      this.deactiveSidebar = false;
    }, 1000);
  }

  private handleActiveUrl(url: string) {
    this.activeUrl = url;
    const chosenPath = this.activeUrl
      ? this.paths.find((p: any) => this.activeUrl.includes(p.drop))
      : null;
    this.lastOpenedSidebar = (chosenPath as any)?.drop ? (chosenPath as any).drop : '';
    if (this.wide) {
      this.openedSidebar = this.lastOpenedSidebar;
    } else {
      this.openedSidebar = '';
    }
  }

  ngOnChanges() {
    this.paths = Object.values(this.pathsObj);

    if (this.organizationConfigMenu && this.organizationConfigMenu.length > 0) {
      const menu = this.organizationConfigMenu;
      if (menu instanceof Array && menu.length > 0) {
        menu.forEach((m: LcMenuEntry) => {
          let entry: any;
          if (m.children instanceof Array && m.children.length > 0) {
            entry = {
              title: m.name.toUpperCase(),
              show: true,
              drop: m.path,
              icons: {},
              subpaths: []
            };
          } else {
            entry = {
              title: m.name.toUpperCase(),
              show: true,
              url: m.path,
              icons: {},
              subpaths: []
            };
          }
          if (m.icon) {
            entry.icons[this.style] = {
              faIcon: m.icon
            };
          }
          if (m.children instanceof Array) {
            m.children.forEach(sm => {
              entry.subpaths.push({
                title: sm.name.toUpperCase(),
                url: sm.path
              });
            });
          }
          if (m.menuPositionIdx) {
            this.paths.splice(m.menuPositionIdx, 0, entry);
          } else {
            this.paths.push(entry);
          }
        });
      }
    }
  }

  ngAfterViewInit() {
    this.getScreenSize();
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.routerSubscr.unsubscribe();
  }

  toggleMenu(event: string) {
    this.lastOpenedSidebar = event;
    this.openedSidebar = event;
  }

  toggleSidebar() {
    this.showSidebar = this.showSidebar === 'Show menu' ? 'Hide menu' : 'Show menu';
    if (this.showSidebar === 'Show menu') {
      this.openedSidebar = '';
      this.wide = false;
      this.closedManualy = true;
    } else {
      this.openedSidebar = this.lastOpenedSidebar;
      this.wide = true;
      this.closedManualy = false;
    }
    this.fakeResize = true;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      this.fakeResize = false;
    }, 0);
  }
}
