<header>
  <nav class="navbar" [ngClass]="{ show: openMenu }">
    <div class="nav-item nav-toggler" *ngIf="!largeWindow">
      <button
        class="btn btn-light btn-nav toggler"
        type="button"
        aria-label="Toggle menu"
        (click)="toggleMenu()"
        (click)="$event.stopPropagation()">
        <fa-icon [icon]="barIcon"></fa-icon>
      </button>
    </div>

    <div class="nav-item logo">
      <div id="logo">
        <a [routerLink]="['/']">
          <img src="assets/big-xyt-icon-medium.png" />
          <span class="logo-text">Metrics Tracker</span>
        </a>
      </div>
    </div>

    <ol
      class="navbar-nav"
      [ngClass]="{ 'collapse navbar-collapse show': openMenu }"
      *ngIf="openMenu || largeWindow">
      <li *ngIf="isAuthenticated && largeWindow">
        <button
          id="screenToggler"
          class="nav-item btn btn-nav"
          [title]="screen"
          (click)="toggleScreen($event)">
          <fa-icon [icon]="screenIcon" size="lg"></fa-icon>
        </button>
      </li>

      <li *ngFor="let link of links">
        <button class="nav-item btn btn-nav" routerLinkActive="currentLink" [routerLink]="link.url">
          {{ link.displayed }}
        </button>
      </li>

      <ng-container *ngIf="!isAuthenticated">
        <li>
          <button
            class="nav-item btn btn-nav"
            routerLinkActive="currentLink"
            [routerLink]="['/signup']">
            Sign up
          </button>
        </li>

        <li>
          <button
            class="nav-item btn btn-nav"
            routerLinkActive="currentLink"
            [routerLink]="['/login']">
            Log In
          </button>
        </li>
      </ng-container>
    </ol>

    <ul class="navbar-nav" *ngIf="isAuthenticated">
      <li ngbDropdown #myDrop="ngbDropdown">
        <button
          class="nav-item btn btn-nav user-edit-manual"
          id="userEditManual"
          (click)="myDrop.toggle()"
          title="{{ isAuthenticated.email }}">
          <div class="btn btn-primary dropdown-nav toggler user-edit-manual">
            {{ initials }}
          </div>
        </button>

        <div id="menu" ngbDropdownMenu aria-labelledby="userEditManual">
          <div class="card card-secondary">
            <div class="card-body">
              <div class="dropdown-items">
                <div class="dropdown-column1">
                  <div class="circle-big badge badge-primary">
                    {{ initials }}
                  </div>
                </div>
                <div class="dropdown-column2">
                  <p class="card-text">
                    {{ isAuthenticated.email ? isAuthenticated.email : '' }}<br />
                    <span *ngIf="isAuthenticated.firstName || isAuthenticated.lastName"
                      >{{ isAuthenticated.firstName ? isAuthenticated.firstName : '' }}
                      {{ isAuthenticated.lastName ? isAuthenticated.lastName : '' }}
                    </span>
                  </p>
                  <button
                    id="userEdit"
                    class="btn btn-light btn-sm"
                    (click)="showUserData($event)"
                    (click)="myDrop.close()">
                    Manage account
                  </button>
                </div>
              </div>
            </div>
            <div class="card-header">
              <button
                id="logout"
                class="btn btn-light btn-sm float-right"
                (click)="logoutUser($event)">
                Sign out
              </button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</header>
