import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as fromNgBigxyt from 'ng-bigxyt';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { SharedModule } from './modules/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const assetsUrl = './assets/config/config.json';

let RECAPTCHA_KEY: string;

export function init_app(
  appConfigService: fromNgBigxyt.AppSettingsLoaderService
): () => Promise<string> {
  return () =>
    new Promise((resolve, reject) => {
      appConfigService.load().then((key: fromNgBigxyt.Configuration) => {
        if (key) {
          RECAPTCHA_KEY = key.recaptchaKey;
          resolve(RECAPTCHA_KEY);
        }
      });
    });
}

export function get_recaptcha_key(): string {
  return RECAPTCHA_KEY;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    RecaptchaV3Module,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [
    fromNgBigxyt.AppSettingsLoaderService,
    fromNgBigxyt.HttpOptionService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [fromNgBigxyt.AppSettingsLoaderService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: fromNgBigxyt.RefreshTokenInterceptor, multi: true },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: get_recaptcha_key
    },
    {
      provide: 'Window',
      useValue: window
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
