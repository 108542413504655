import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpOptionService } from 'ng-bigxyt';
import { Observable } from 'rxjs';

export enum HtmlType {
  agreement = 'agreement',
  loginError = 'login-error'
}

@Injectable({
  providedIn: 'root'
})
export class GetHtmlService {
  constructor(
    private optionService: HttpOptionService,
    private http: HttpClient
  ) {}

  getHtml(type?: HtmlType, givenPath?: string): Observable<any> {
    let path = '';
    if (givenPath) {
      path = givenPath;
    } else if (type) {
      const appName = this.optionService.config.name;
      path = `assets/brand/${appName}-${type}.html`;
    }
    return path ? this.http.get(path, { responseType: 'text' }) : null;
  }
}
