<nav class="navbar" (mouseleave)="!wide ? toggleMenu('') : null">
  <ul class="navbar-nav" [ngClass]="{ shrinked: !wide }">
    <ng-container *ngFor="let path of paths">
      <ng-container *ngIf="path.drop; else noDrop">
        <div
          class="drop-down"
          [ngClass]="{
            active: (activeUrl && activeUrl.includes(path.drop)) || openedSidebar === path.drop
          }"
          *ngIf="path.show">
          <li class="nav-item drop" id="path.drop">
            <a
              class="nav-link toggler"
              (click)="toggleMenu(openedSidebar === path.drop ? '' : path.drop)"
              *ngIf="wide; else noWide"
              [ngClass]="{
                currentLink:
                  (activeUrl && activeUrl.includes(path.drop)) || openedSidebar === path.drop
              }">
              <div class="icons" *ngIf="path.icons && path.icons[style]">
                <fa-icon
                  [icon]="path.icons[style].faIcon"
                  [rotate]="path.icons[style].rotate"
                  class="mr-auto">
                </fa-icon>
              </div>

              <span class="title ml-1">{{ path.title }}</span>
              <span class="float-right text-muted">
                <fa-icon
                  [icon]="rightIcon"
                  size="2x"
                  class="ml-3"
                  *ngIf="openedSidebar !== path.drop"></fa-icon>
                <fa-icon
                  [icon]="downIcon"
                  size="2x"
                  class="ml-3"
                  *ngIf="openedSidebar === path.drop"></fa-icon>
              </span>
            </a>

            <ng-template #noWide>
              <a
                class="nav-link toggler"
                (mouseenter)="!deactiveSidebar ? toggleMenu(path.drop) : null"
                title="{{ path.title }}"
                [ngClass]="{
                  currentLink:
                    (activeUrl && activeUrl.includes(path.drop)) || openedSidebar === path.drop
                }">
                <div class="icons" *ngIf="path.icons && path.icons[style]">
                  <fa-icon
                    [icon]="path.icons[style].faIcon"
                    [rotate]="path.icons[style].rotate"
                    class="mr-auto">
                  </fa-icon>
                </div>
              </a>
            </ng-template>
          </li>

          <li class="nav-item menu" *ngIf="openedSidebar === path.drop">
            <ol aria-labelledby="dropdownManual" class="navbar-nav" *ngIf="wide; else noWide2">
              <li class="nav-item" *ngFor="let subpath of path.subpaths">
                <a
                  class="nav-link"
                  routerLinkActive="currentLink"
                  [routerLink]="['/app', path.drop, subpath.url]"
                  [ngClass]="{ currentLink: activeUrl && activeUrl.includes(subpath.url) }">
                  <span [innerHTML]="subpath.title" class="with-margin"></span>
                </a>
              </li>
            </ol>

            <ng-template #noWide2>
              <ol
                aria-labelledby="dropdownManual"
                class="navbar-nav shrinked"
                (mouseenter)="!deactiveSidebar ? toggleMenu(path.drop) : null"
                (mouseleave)="toggleMenu('')">
                <li class="nav-item" *ngFor="let subpath of path.subpaths">
                  <a
                    class="nav-link"
                    routerLinkActive="currentLink"
                    [routerLink]="['/app', path.drop, subpath.url]"
                    [ngClass]="{ currentLink: activeUrl && activeUrl.includes(subpath.url) }">
                    <span [innerHTML]="subpath.title"></span>
                  </a>
                </li>
              </ol>
            </ng-template>
          </li>
        </div>
      </ng-container>

      <ng-template #noDrop>
        <li class="nav-item" *ngIf="path.show">
          <a
            class="nav-link"
            routerLinkActive="currentLink"
            [routerLink]="['/app', path.url]"
            *ngIf="wide; else noWide3">
            <div class="icons" *ngIf="path.icons && path.icons[style]">
              <fa-icon
                [icon]="path.icons[style].faIcon"
                [rotate]="path.icons[style].rotate"
                class="mr-auto"></fa-icon>
            </div>
            <span [innerHTML]="path.title" class="title ml-1"></span>
          </a>

          <ng-template #noWide3>
            <a
              class="nav-link"
              routerLinkActive="currentLink"
              [routerLink]="['/app', path.url]"
              title="{{ path.title }}"
              (mouseenter)="toggleMenu('')">
              <div class="icons" *ngIf="path.icons && path.icons[style]">
                <fa-icon
                  [icon]="path.icons[style].faIcon"
                  [rotate]="path.icons[style].rotate"
                  class="mr-auto">
                </fa-icon>
              </div>
            </a>
          </ng-template>
        </li>
      </ng-template>
    </ng-container>
  </ul>

  <ul class="navbar-nav with-etf" [ngClass]="{ shrinked: !wide }">
    <li class="nav-item with-button" *ngIf="!smallWindow">
      <div
        class="sidebar-toggler"
        [ngStyle]="{ left: getPosition() }"
        [ngClass]="{ shrinked2: !wide }">
        <button
          id="sidebarToggler"
          class="nav-link"
          (click)="toggleSidebar()"
          [title]="showSidebar">
          <fa-icon [icon]="outdentIcon" size="lg" *ngIf="wide"></fa-icon>
          <fa-icon [icon]="indentIcon" size="lg" *ngIf="!wide"></fa-icon>
        </button>
      </div>
    </li>
  </ul>
</nav>
