<div class="bg-light wrapper-md box-shadow" *ngIf="routeData && routeData.title">
  <h3>
    <span [innerHtml]="routeData.title"></span>
    <sup *ngIf="routeData.hasTooltip" class="ml-1">
      <ng-template #tipContent>{{ routeData.hasTooltip }}</ng-template>
      <i [ngbTooltip]="tipContent" placement="right" tooltipClass="custom-tooltip">
        <small>
          <fa-icon [icon]="infoIcon"></fa-icon>
        </small>
      </i>
    </sup>
  </h3>
</div>
<router-outlet></router-outlet>
