<div id="main-container">
  <app-header [isAuthenticated]="auth" (logout)="logout($event)" (edit)="manageWindow($event)">
  </app-header>
  <xyt-user-edit
    *ngIf="showModal"
    [isAuthenticated]="auth"
    [show]="showModal"
    [show2fa]="show2fa"
    (close)="manageWindow($event)">
  </xyt-user-edit>
  <div class="content-container">
    <div class="content">
      <div class="router-content">
        <ng-content></ng-content>
      </div>
      <xyt-footer></xyt-footer>
    </div>
  </div>
</div>
