import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { faInfoCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShellComponent implements OnInit, OnDestroy {
  routeData: any;
  routerSubscr: Subscription;
  infoIcon: IconDefinition = faInfoCircle;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.routeData = this.setChild();
    this.subscribeRouter();
  }

  private setChild(): any {
    let child = this.route.firstChild;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data.title) {
        const snapshotData = child.snapshot.data;
        return {
          title: snapshotData.title
        };
      } else {
        return null;
      }
    }
    return null;
  }

  subscribeRouter() {
    this.routerSubscr = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.setChild())
      )
      .subscribe((data: any) => {
        if (data) {
          this.routeData = data;
        }
      });
  }

  ngOnDestroy() {
    this.routerSubscr.unsubscribe();
    this.modalService.dismissAll();
  }
}
