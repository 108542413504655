import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { AppSettingsLoaderService, HttpOptionService, XytAbstractApp } from 'ng-bigxyt';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

declare global {
  interface Window {
    gtag: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends XytAbstractApp implements OnInit, OnDestroy {
  routerSubscr: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    protected httpOptionService: HttpOptionService,
    protected angularticsTagManager: Angulartics2GoogleTagManager,
    protected consentService: NgcCookieConsentService,
    protected appSettingsLoaderService: AppSettingsLoaderService,
    @Inject('Window') protected window: Window
  ) {
    super(
      httpOptionService,
      angularticsTagManager,
      consentService,
      appSettingsLoaderService,
      window
    );
  }

  ngOnInit() {
    this.checkVersion();
    this.subscribeRouter();
  }

  subscribeRouter() {
    this.routerSubscr = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data.title) {
              return child.snapshot.data.title;
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: string) => {
        if (data) {
          const abr = this.httpOptionService.config.name;
          this.title.setTitle(`Realtime sample - ${data}`);
        }
      });
  }

  ngOnDestroy(): void {
    this.versionSubscr.unsubscribe();
    this.routerSubscr.unsubscribe();
  }
}
