import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as fromNgBigxyt from 'ng-bigxyt';
import { PasswordComponent } from './modules/core/pages/password/password.component';
import { ShellComponent } from './modules/core/pages/shell/shell.component';
import { SigninComponent } from './modules/core/pages/signin/signin.component';
import { SignupComponent } from './modules/core/pages/signup/signup.component';

const routes: Routes = [
  {
    path: 'login',
    component: SigninComponent,
    data: { title: 'Sign In' }
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: { title: 'Sign Up' }
  },
  {
    path: 'contact-us',
    component: fromNgBigxyt.XytContactComponent,
    data: { title: 'Contact us' }
  },
  {
    path: 'password',
    component: PasswordComponent,
    canActivate: [fromNgBigxyt.PasswordCanActivateService],
    children: [
      {
        path: 'reset',
        component: fromNgBigxyt.XytResetPasswordComponent,
        data: { title: 'Forgot Password' }
      },
      {
        path: 'change',
        component: fromNgBigxyt.XytChangePasswordComponent,
        data: { title: 'Change Password' }
      },
      {
        path: '',
        redirectTo: 'reset',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '404',
    component: fromNgBigxyt.XytPageNotFoundComponent
  },
  {
    path: 'app',
    component: ShellComponent,
    canActivate: [fromNgBigxyt.CanActivateService],
    canLoad: [fromNgBigxyt.CanLoadService],
    loadChildren: () => import('./modules/data/data.module').then(m => m.DataModule)
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
