import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject } from '@angular/core';
import { faBars, faCompress, faExpand } from '@fortawesome/free-solid-svg-icons';
import { HttpOptionService, XytAbstractHeader } from 'ng-bigxyt';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends XytAbstractHeader implements AfterViewInit {
  url = '/data/user-edit';
  screen = 'Full screen';
  elem: any;
  links = [];
  largeWindow = true;
  openMenu = false;
  screenIcon = faExpand;
  barIcon = faBars;
  hideApps: string[];

  @HostListener(
    'document: fullscreenchange' ||
      'document: webkitfullscreenchange' ||
      'document: mozfullscreenchange' ||
      'document: MSFullscreenChange',
    ['$event']
  )
  onScreenChange(ev: Event) {
    this.setScreenProperty();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    if (window.innerWidth <= 780) {
      this.largeWindow = false;
    } else {
      this.largeWindow = true;
    }
  }

  @HostListener('window:click', ['$event'])
  closeMenu(event: MouseEvent) {
    if (!this.largeWindow && this.openMenu) {
      this.openMenu = false;
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private httpOptionService: HttpOptionService,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.hideApps = this.httpOptionService.config.hideApps
      ? this.httpOptionService.config.hideApps
      : [];
  }

  ngAfterViewInit() {
    this.getScreenSize();
    this.cd.detectChanges();
  }

  toggleMenu() {
    this.openMenu = this.openMenu ? false : true;
  }

  toggleScreen(event: any) {
    this.elem = document.documentElement;
    if (this.screen === 'Full screen') {
      this.openFullScreen();
    } else {
      this.closeFullScreen();
    }
  }

  setScreenProperty() {
    if (
      this.document.fullscreenElement ||
      this.document.mozFullScreenElement ||
      this.document.webkitFullscreenElement ||
      this.document.msFullscreenElement
    ) {
      this.screen = 'Close full screen';
      this.screenIcon = faCompress;
    } else {
      this.screen = 'Full screen';
      this.screenIcon = faExpand;
    }
  }

  openFullScreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  closeFullScreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }
}
