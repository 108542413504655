import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  AppState,
  Auth,
  getAppOrganizationConfigs,
  getAuth,
  HttpOptionService,
  XytAbstractWrapper
} from 'ng-bigxyt';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html'
})
export class WrapperComponent extends XytAbstractWrapper implements OnInit, OnDestroy {
  routerSubscr: Subscription;
  canShowSidebar: boolean;
  configSubscr: Subscription;
  organizationConfig: object;
  authSubscr: Subscription;
  auth: Auth;
  show2fa: boolean;

  constructor(
    protected store: Store<AppState>,
    private router: Router,
    private optionService: HttpOptionService
  ) {
    super(store);
    this.show2fa = this.optionService.config.mfaEnabled ? true : false;
  }

  ngOnInit() {
    this.subscribeRouter();
    this.authSubscr = this.store.pipe(select(getAuth)).subscribe(data => (this.auth = data));
    this.configSubscr = this.store
      .pipe(select(getAppOrganizationConfigs))
      .subscribe(data => (this.organizationConfig = data));
  }

  ngOnDestroy() {
    this.routerSubscr.unsubscribe();
    this.authSubscr.unsubscribe();
    this.configSubscr.unsubscribe();
  }

  subscribeRouter() {
    this.routerSubscr = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((evnt: NavigationEnd) => evnt.urlAfterRedirects)
      )
      .subscribe((data: string) => {
        if (data && (data.startsWith('/app') || data.startsWith('/login'))) {
          this.canShowSidebar = false;
        } else {
          this.canShowSidebar = true;
        }
      });
  }
}
