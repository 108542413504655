import { Component } from '@angular/core';
import { GetHtmlService, HtmlType } from '../../services/get-html.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html'
})
export class SigninComponent {
  loginError: string;

  constructor(private service: GetHtmlService) {
    this.service.getHtml(HtmlType.loginError).subscribe({
      next: (data: any) => (this.loginError = data)
    });
  }
}
