import { NgModule, Optional, SkipSelf } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { XytCoreModule, XytSharedModule } from 'ng-bigxyt';
import { SharedModule } from '../shared/shared.module';
import { PasswordComponent } from './pages/password/password.component';
import { ShellComponent } from './pages/shell/shell.component';
import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';

@NgModule({
  declarations: [ShellComponent, SignupComponent, PasswordComponent, SigninComponent],
  imports: [XytSharedModule, XytCoreModule, SharedModule, NgbTooltipModule, StoreDevtoolsModule]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('You should import core module only in the root module');
    }
  }
}
